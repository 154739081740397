import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, EntityTypeFieldDto, OrganizationFieldDto, TargetTypeEnum } from '@shared/classes';
import { OrganizationsMappingService } from '@shared/services/mappings/entity/organizations-mapping.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { EntityTypesDataService } from '../../services/data/entity-types-data.service';

@Component({
  selector: 'app-organizations-item-form',
  templateUrl: './organizations-item-form.component.html',
  styleUrls: ['./organizations-item-form.component.scss'],
})
export class OrganizationsItemFormComponent extends BaseForm<OrganizationFieldDto> implements OnInit {
  @Input() itemId: string = null;
  organizationsType: TargetTypeEnum = TargetTypeEnum.Organization;
  categoryList = [EntityTypeFieldDto.CategoryEnum.Organization];
  constructor(
    public viewModeService: ViewModeService,
    private entityTypeRequestService: EntityTypesDataService,
    public mapperService: OrganizationsMappingService
  ) {
    super(viewModeService, 'ORGANIZATION');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.formatGetChangedFormValues(this.getChangedFormValues().updateItems);
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    ret.type = ret.type.code;
    return ret;
  }
  setData(data: any) {
    let patchVal = { ...data };
    if (data?.entityType) patchVal.type = { ...data.entityType };
    this.patchFormData(patchVal);
    this.data = data;

    if (this.itemId) {
      this.formGroup.get('type').disable();
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      // organization: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      description: new FormControl(null),
      // category: new FormControl(Entity.CategoryEnum.Responsibilities),
      dynamics: new FormGroup({}),
    });
  }
  formatGetChangedFormValues(updateItems: { key: string; value: any }[]) {
    let items = [];
    updateItems.forEach((element) => {
      if (element.key == 'type') {
        element.value = element?.value?.code;
      }
      items.push(element);
    });
    return items;
  }
  entityType: EntityTypeFieldDto = null;
  entityTypeLoading: boolean = false;
  onEntityTypeChange(value) {
    // if(!!!value) return;
    // this.entityTypeLoading = true;
    // this.entityTypeRequestService.getByIdOrCode(value?.code, { showLoading: false, showMsg: false }).pipe(takeUntil(this.destroy$)).subscribe(
    //     {
    //         next: (res) => {
    //             this.entityType = res.data;
    //             this.entityTypeLoading = false;
    //         },
    //         error: (err) => {
    //             this.entityTypeLoading = false;
    //         }
    //     })
  }
  checkValueChanged(formCurrentData: any, key: string) {
    return this.data && (key == 'type' ? formCurrentData[key]?.code : formCurrentData[key]) != this.data[key];
  }
}
